import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Wrapper, Button, Grid } from "Components";
import { Typography } from "antd";
import moment from "moment";
//service
import MixPanel from "Services/MixPanelService";
import { FaLock } from "react-icons/fa";

const PlanIcon = ({ plan }) => {
	switch (plan) {
		case "Oro":
			return (
				<img
					src="https://polpo-assets.s3.amazonaws.com/production/FibSight/Logo/Gold.svg"
					alt="gold"
				/>
			);
		case "Diamante":
			return (
				<img
					src="https://polpo-assets.s3.amazonaws.com/production/FibSight/Logo/Diamont.svg"
					alt="diamont"
				/>
			);
		default:
			return (
				<img
					src="https://polpo-assets.s3.amazonaws.com/production/FibSight/Logo/Gold.svg"
					alt="gold"
				/>
			);
	}
};

const ViewBeforeVideo = ({
	isLive,
	liveRoomId,
	resource,
	categoryRef,
	poster,
	currentUser,
	ROUTES,
	fetchResourceMoreViewsCount,
	setVideoProperties,
	organizationId,
	mobileView,
	organizationName,
	modalPreviewDataView,
}) => {
	const {
		id,
		name,
		speakers,
		description,
		resourceUrl,
		isChatAvailable,
		rooms,
		resourceType,
		settings,
	} = resource;

	const toLink =
		isLive && liveRoomId
			? `${ROUTES.ROOM}/${liveRoomId}/${id}${
					categoryRef ? `?ref=${categoryRef}` : ""
			  }`
			: `${ROUTES.VIDEO}/${id}${categoryRef ? `?ref=${categoryRef}` : ""}`;

	const evaluationLink = `/resource/finalTest/${name}/${id}`;
	const [aviableVideo, setAviableVideo] = useState(false);
	const [aviableEvaluation, setAviableEvaluation] = useState(false);
	const [isloading, setIsloading] = useState(false);

	const { Title } = Typography;

	const getIsAviableResource = useCallback(async () => {
		setIsloading(true);
		try {
			const resp = await fetch(
				`${process.env.REACT_APP_API_URL}organizations/1/resources/${id}/permissions`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `${currentUser.token}`,
					},
				}
			);
			const data = await resp.json();
			setAviableVideo(data.watchVideoResourceAllowed);
			setAviableEvaluation(data.autoEvaluationAllowed);
			setIsloading(false);
			// console.log(data);
		} catch (error) {
			console.log(error);
			setIsloading(false);
		}
	}, [id, currentUser.token]);

	useEffect(() => {
		getIsAviableResource();
	}, [getIsAviableResource]);

	const Tooltip = ({ children, tooltipText }) => {

		const [show, setShow] = useState(false);


		return (
			<div className="relative inline-block">
				<div
					className=" cursor-pointer"
					onMouseEnter={() => setShow(true)}
					onMouseLeave={() => setShow(false)}
				>
					{children}
				</div>
				<div
					className="p-2 bg-gray-800 text-white text-sm absolute z-10 bottom-full left-1/2 transform -translate-x-1/2
			mb-3 w-36 rounded-md font-medium shadow-lg
			pointer-events-none transition-all ease-linear duration-300
			"
					style={{ opacity: show ? 1 : 0 }}
				>
					{tooltipText}
				</div>
			</div>
		);
	  };

	return (
		<div
			style={{
				display: "grid",
				gridTemplateRows: "auto",
				gridTemplateColumns: "100%",
			}}
		>
			<div
				style={{
					backgroundImage: `url(${poster})`,
					backgroundSize: "cover",
					height: mobileView ? "calc((100vw - 16px) * 0.5625)" : "433px",
				}}
			>
				<div
					style={{
						width: "100%",
						height: "100%",
						position: "relative",
					}}
				>
					{!modalPreviewDataView.isExclusive && (
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								gap: "40px",
								position: "absolute",
								bottom: "20px",
								left: "20px",
							}}
						>
							<Fragment>
								{isloading ? (
									<div />
								) : (
									<Fragment>
										{aviableVideo ? (
											<Link to={toLink}>
												<Button
													primary={true}
													backgroundColor="#2CA8D5"
													onClick={() => {
														MixPanel.track(MixPanel.TYPES.ENTER_EVENT_ONLINE, {
															userId: currentUser.id,
															EventDate: moment().format(
																"MMMM Do YYYY, h:mm:ss a"
															),
															EventName: name,
															liveRoomId: liveRoomId,
															userName: currentUser.name,
															firsLastName: currentUser.firstLastName,
															email: currentUser.email,
															organizationName: organizationName,
														});

														MixPanel.track(MixPanel.TYPES.CLICK_RESOURCE, {
															id,
															resourceType: resourceType.key,
															organizationName: organizationName,
															userId: currentUser.id,
															agent: window.navigator.userAgent,
															userName: currentUser.name,
															firsLastName: currentUser.firstLastName,
															email: currentUser.email,
														});
														fetchResourceMoreViewsCount(currentUser.token, {
															organizationId,
															resourceId: id,
														});
														setVideoProperties({
															videoId: id,
															videoUrl: resourceUrl,
															withChat: isChatAvailable,
															streamId: rooms[0]?.streamId,
															streamWss: rooms[0]?.streamWss,
														});
													}}
												>
													Ver video
												</Button>
											</Link>
										) : (
											<Tooltip tooltipText="Acceso denegado: Por favor, visualiza el video anterior antes de intentar ver este.">
												<Button
													primary={true}
													backgroundColor="rgba(0, 0, 13, 0.8)"
													style={{
														cursor: "not-allowed",
														color: "white",
														display: "flex",
														alignItems: "center",
														gap: "8px",
														justifyContent: "center",
													}}
													disabled={aviableVideo}
												>
													Ver video
													<FaLock />
												</Button>
											</Tooltip>
										)}

										{settings.evaluation && (
											<Fragment>
												{aviableEvaluation ? (
													<Link to={evaluationLink}>
														<Button primary={true} backgroundColor="#2CA8D5">
															Autoevaluación
														</Button>
													</Link>
												) : (
													<Tooltip tooltipText="Acceso denegado: No puedes para realizar esta autoevaluación. Por favor, mira el video antes de continuar">
														<Button
														primary={true}
														backgroundColor="rgba(0, 0, 13, 0.8)"
														style={{
															cursor: "not-allowed",
															color: "white",
															display: "flex",
															alignItems: "center",
															gap: "8px",
															justifyContent: "center",
														}}
														disabled={aviableVideo}
														>
														Autoevaluación
														<FaLock />
														</Button>
												  </Tooltip>
												)}
											</Fragment>
										)}
									</Fragment>
								)}
							</Fragment>
						</div>
					)}
					{modalPreviewDataView.isExclusive && (
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								gap: "40px",
								position: "absolute",
								bottom: "20px",
								left: "20px",
							}}
						>
							<a
								href="/#"
								className="py-2 px-3 flex text-white no-animation font-bold uppercase justify-center items-center"
								target="_blank"
								style={{
									backgroundColor: "#0091C2",
								}}
							>
								Ver planes
							</a>
						</div>
					)}
				</div>
			</div>
			<Grid display="Grid" columns="repeat(auto-fit, minmax(240px, 1fr))">
				<Wrapper
					display="flex"
					flexDirection="column"
					padding="24px"
					alignItems="flex-start"
					justifyContent="flex-start"
				>
					<Title
						style={{ color: "white", fontWeight: "700", fontSize: "24px" }}
					>
						{name}
					</Title>
					<p style={{ color: "white", fontWeight: "400", fontSize: "14px" }}>
						{description}
					</p>
				</Wrapper>
				{modalPreviewDataView.isExclusive ? (
					<Wrapper
						display="flex"
						flexDirection="column"
						padding="24px"
						height="100%"
						alignItems="flex-start"
						justifyContent="flex-start"
					>
						<Title
							style={{ color: "white", fontWeight: "700", fontSize: "18px" }}
						>
							<div className="flex items-baseline gap-3 w-full">
								<PlanIcon plan={modalPreviewDataView.plan} />
								<span className="xl:text-xl uppercase text-white">
									Contenido exclusivo
								</span>
							</div>{" "}
						</Title>
						<div className="flex justify-center items-center w-1/2 h-3/4 self-center">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="49.514"
								height="66.352"
								viewBox="0 0 49.514 66.352"
							>
								<path
									id="Path_63"
									data-name="Path 63"
									d="M58.265,29.807V21.454a18.954,18.954,0,1,0-37.908,0v8.353h-5.8V68.852H64.068V29.807Zm-7.39,0H27.747V21.454a11.564,11.564,0,1,1,23.127,0Z"
									transform="translate(-14.554 -2.5)"
									fill="#fff"
								/>
							</svg>
						</div>
					</Wrapper>
				) : (
					<Wrapper
						display={speakers.length > 0 ? "flex" : "none"}
						flexDirection="column"
						padding="24px"
						height="100%"
						alignItems="flex-start"
						
					>
						<Title
							style={{ color: "white", fontWeight: "700", fontSize: "18px" }}
						>
							Speaker
						</Title>
						<ul style={{ listStyle: "none" }}>
							{speakers.map((value, index) => {
								return (
									<React.Fragment key={index}>
										<li
											style={{
												color: "white",
												fontWeight: "400",
												fontSize: "16px",
											}}
										>{`${value.name}`}</li>
									</React.Fragment>
								);
							})}
						</ul>
					</Wrapper>
				)}
			</Grid>
		</div>
	);
};
export default ViewBeforeVideo;
