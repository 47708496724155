import React, { useState } from "react";
import PropTypes from "prop-types";

//component
import moment from "moment";
import { SpinnerButton, InformativeMsg } from "Components";
//Icon
import { BiBookAdd } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
//Service
import {
  setAssigment,
  getAssigmentSpecificResource,
  deleteAssigment,
} from "Services/AssigmentService";

const shipmentDate = (date) => {
  //format Date with moment
  const assigmentDate = moment(date).format("DD/MM/YYYY");

  return assigmentDate;
};
//get dinamit File name
const getName = (file, id) => {
  const nameFile = Array.isArray(file) && file.find((value) => value.id === id);

  return nameFile.fileName;
};

const CardAssigment = ({
  valueDate,
  currentUser,
  organizationId,
  setMyAssigment,
}) => {
  //Array Value
  const {
    allowUntil,
    description,
    downloadableContentLink,
    id,
    settings,
    title,
    users,
  } = valueDate;

  //useState
  const [file, setFile] = useState([]);
  const [msgInfo, setMsgInfo] = useState({
    id: null,
    msgSuccess: null,
    msgError: null,
    msgDeleteError: null,
    msgDeleteSuccess: null,
  });
  const [valiLoading, setValiLoading] = useState({
    id: null,
    loading: false,
    loadingError: false,
  });

  return (
    <div
      className={`card mb-3 lg:mt-0 glass lg:card-side text-neutral-content 
    w-full lg:w-vw-22 lg:mb-5 `}
    >
      <figure className="p-6">
        <img
          src="https://polpo-assets.s3.amazonaws.com/production/FibSight/Fondo/Daily-Visual-Practice-in-NetEase-Bootcamp-3+(1).png"
          className="rounded-lg shadow-lg lg:h-60 mask mask-hexagon"
          alt=""
        />
      </figure>
      <div className="max-w-md card-body">
        {moment(allowUntil).isSameOrBefore(moment().format("YYYY-MM-DD")) &&
          users?.length === 0 && (
            <h1
              style={{
                color: "rgba(239, 68, 68, var(--tw-text-opacity))",
              }}
              className="text-center text-2xl font-bold"
            >
              Esta tarea no fue entregada
            </h1>
          )}
        {/* attachmentLink */}
        {users[0]?.UserHomework.isSent &&
        !users[0]?.UserHomework.attachmentLink.includes("#") ? (
          <h1
            style={{
              color: "#2f9b70",
            }}
            className="text-center text-2xl font-bold"
          >
            Esta tarea fue entregada
          </h1>
        ) : (
          users[0]?.UserHomework.attachmentLink.includes("#") && (
            <h1
              style={{
                color: "rgba(239, 68, 68, var(--tw-text-opacity))",
              }}
              className="text-center text-2xl font-bold"
            >
              Esta tarea no fue entregada
            </h1>
          )
        )}
        <h2 className="card-title">{title}</h2>
        <span>
          Fecha de entrega:
          {!moment(allowUntil).isSameOrBefore(moment().format("YYYY-MM-DD")) ? (
            <span className="px-1">{shipmentDate(allowUntil)}</span>
          ) : (
            <span className="text-red-500">
              <span className="px-1">La fecha a expirado</span>
              {shipmentDate(allowUntil)}
            </span>
          )}
        </span>
        <span className="text-lg font-semibold">
          {settings?.teacherEmails[0]}
        </span>
        {downloadableContentLink && (
          <a
            className="no-underline text-leg font-semibold cursor-pointer"
            href={downloadableContentLink}
            download
          >
            Descargar Recurso
          </a>
        )}
        <p>{description}</p>
        {users?.length === 0 ? (
          <div
            className={`relative border-dotted h-32 rounded-lg border-dashed border-2
        ${
          file.some((value) => value.id === id)
            ? "border-green-600"
            : "border-white"
        } glass flex justify-center items-center`}
          >
            <div className="absolute">
              <div className="flex flex-col items-center">
                <i
                  className={`fa fa-folder-open fa-4x ${
                    file.some((value) => value.id === id)
                      ? "text-green-500"
                      : "text-white"
                  }`}
                ></i>
                <span
                  className={`block ${
                    file.some((value) => value.id === id)
                      ? "text-green-500"
                      : "text-white"
                  } font-normal`}
                >
                  {file.some((value) => value.id === id)
                    ? getName(file, id)
                    : "Adjunte su documento aquí"}
                </span>
              </div>
            </div>
            <input
              type="file"
              className="h-full w-full opacity-0"
              onChange={(e) => {
                setFile([
                  ...file,
                  {
                    id: id,
                    fileName: e.target.files[0].name,
                    file: e.target.files,
                  },
                ]);
              }}
            />
          </div>
        ) : (
          !users[0]?.UserHomework.attachmentLink.includes("#") && (
            <div className="alert alert-success">
              <div className="flex-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  className="w-6 h-6 mx-2 stroke-current"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
                  ></path>
                </svg>
                <span>Ya has adjuntado un archivo </span>
                <a
                  className="no-underline text-leg font-semibold cursor-pointer pl-1"
                  href={users[0].UserHomework.attachmentLink}
                  download
                >
                  Descargue aqui
                </a>
              </div>
            </div>
          )
        )}
        {!users[0]?.UserHomework.isSent && (
          <div className="card-actions flex justify-between lg:block">
            <button
              onClick={async () => {
                const { token } = currentUser;

                const location = window.location.pathname;
                const resourceId = location.split("/")[3];

                if (file.some((value) => value.id === id)) {
                  const fileData =
                    Array.isArray(file) &&
                    file.find((value) => value.id === id);

                  const responseData = {
                    status: null,
                    fetchData: null,
                  };

                  setValiLoading({
                    id: `fileHomeWord${id}`,
                    loading: true,
                  });

                  await setAssigment({
                    file: fileData.file,
                    homeworkId: id,
                    organizationId,
                    resourceId,
                    token,
                  })
                    .then((response) => {
                      responseData.status = response.status;
                      return response.json();
                    })
                    .then((data) => (responseData.fetchData = data.message));

                  setValiLoading({
                    id: `fileHomeWord${id}`,
                    loading: false,
                  });

                  if (responseData.status === 200) {
                    setMsgInfo({
                      id: `fileHomeWord${id}`,
                      msgSuccess: "Sus tarea ha sido adjuntada con éxito. ",
                    });

                    const currentAssigmentData =
                      await getAssigmentSpecificResource({
                        resourceId,
                        token,
                        organizationId,
                      }).then((response) => response.json());

                    setMyAssigment(currentAssigmentData.homeworks);

                    setTimeout(() => {
                      setMsgInfo({
                        id: null,
                        msgSuccess: null,
                        msgError: null,
                      });
                    }, 3000);
                  } else {
                    setMsgInfo({
                      id: `fileHomeWord${id}`,
                      msgError: responseData.fetchData,
                    });

                    setTimeout(() => {
                      setMsgInfo({
                        id: null,
                        msgSuccess: null,
                        msgError: null,
                      });
                    }, 3000);
                  }
                }
              }}
              className="btn glass rounded-full"
            >
              {valiLoading?.id === `fileHomeWord${id}` &&
              valiLoading?.loading ? (
                <SpinnerButton />
              ) : (
                ""
              )}
              {valiLoading?.id === `fileHomeWord${id}` &&
              valiLoading?.loading ? (
                "Cargando"
              ) : (
                <>
                  <BiBookAdd className="pr-2 text-3xl" />
                  Adjunto
                </>
              )}
            </button>
            <button
              onClick={async () => {
                const { token } = currentUser;

                const location = window.location.pathname;
                const resourceId = location.split("/")[3];

                const reponseData = {
                  status: null,
                  response: null,
                };

                setValiLoading({
                  id: `fileHomeWord${id}`,
                  loadingError: true,
                });
                //Delete file
                await deleteAssigment({
                  resourceId,
                  token,
                  organizationId,
                  homeworkId: id,
                })
                  .then((data) => {
                    reponseData.status = data.status;
                    return data.json();
                  })
                  .then((response) => (reponseData.response = response.msg));

                setValiLoading({
                  id: `fileHomeWord${id}`,
                  loadingError: false,
                });

                if (reponseData.status === 200) {
                  //clear file data
                  setFile([]);
                  //send success msg
                  setMsgInfo({
                    id: `fileHomeWord${id}`,
                    msgDeleteSuccess: "Sus tarea ha sido eliminada con éxito. ",
                  });
                  //update assigmentData
                  const currentAssigmentData =
                    await getAssigmentSpecificResource({
                      resourceId,
                      token,
                      organizationId,
                    }).then((response) => response.json());

                  setMyAssigment(currentAssigmentData.homeworks);
                } else {
                  setMsgInfo({
                    id: `fileHomeWord${id}`,
                    msgDeleteError: reponseData.response,
                  });
                }
                setTimeout(() => {
                  setMsgInfo({
                    id: null,
                    msgDeleteError: null,
                    msgDeleteSuccess: null,
                  });
                }, 3000);
              }}
              className="btn btn-error rounded-full"
            >
              {valiLoading?.id === `fileHomeWord${id}` &&
              valiLoading?.loadingError ? (
                <SpinnerButton />
              ) : (
                ""
              )}
              {valiLoading?.id === `fileHomeWord${id}` &&
              valiLoading?.loadingError ? (
                "Cargando"
              ) : (
                <>
                  <MdDelete className="pr-2 text-3xl" />
                  Borrar
                </>
              )}
            </button>
            {msgInfo.id === `fileHomeWord${id}` && msgInfo.msgError && (
              <InformativeMsg msg={msgInfo.msgError} type="errorMsg" />
            )}

            {msgInfo.id === `fileHomeWord${id}` && msgInfo.msgSuccess && (
              <InformativeMsg msg={msgInfo.msgSuccess} type="succeMsg" />
            )}

            {msgInfo.id === `fileHomeWord${id}` && msgInfo.msgDeleteError && (
              <InformativeMsg msg={msgInfo.msgSuccess} type="errorMsg" />
            )}

            {msgInfo.id === `fileHomeWord${id}` && msgInfo.msgDeleteSuccess && (
              <InformativeMsg msg={msgInfo.msgDeleteSuccess} type="succeMsg" />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

CardAssigment.prototype = {
  valueDate: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  organizationId: PropTypes.number.isRequired,
  setMyAssigment: PropTypes.func.isRequired,
};

export default CardAssigment;
