//Libs
import React, { useState } from "react";
import { withRouter } from "react-router";

//Selectors
import {
  selectCurrentUser,
  selectIsNavMenuOpen,
} from "Redux/User/user-selectors";

import { selectCategoriesData } from "Redux/Categories/categories-selectors";
import { orgnizacionUnseleted } from "Redux/DynamicConfigCompany/dcc-action";
import { clearOrganization } from "Redux/Organization/organizarion-action";

import { connect } from "react-redux";
import { compose } from "redux";

//service
import { CompanyService } from "Services/CompanyService";
//Components
import {
  ProfilePhoto,
  ResourceUpload,
  Link,
  SwitchProfile,
  Grid,
  Drawer,
} from "Components";
import Label from "./Label";
//Actions
import { setNavMenuOpen, logout } from "Redux/User/user-actions";
//style
import { BsChevronRight } from "react-icons/bs";
import { AiOutlineDown } from "react-icons/ai";

function MenuHeader({
  currentUser,
  logout,
  mobileView,
  setDropDownShow,
  DropDownShow,
}) {
  return (
    <div className="flex max-h-16 my-6 justify-start items-center border-b-4 border-top-dropdown">
      {mobileView && (
        <div className="flex">
          <ResourceUpload id="profilePhoto">
            <ProfilePhoto
              profilePhotoSrc={currentUser?.profileImageUser}
              containerStyle={{
                right: "5px",
                bottom: "2px",
              }}
              cameraStyle={{
                left: "30px",
                bottom: "0",
                fontSize: "1rem",
              }}
            />
          </ResourceUpload>
          <div className="relative inline-block text-left">
            <div>
              <button
                type="button"
                className={`inline-flex justify-center text-primary-header-menu hover:text-hover-header-menu`}
                id="menu-button"
                aria-expanded="true"
                aria-haspopup="true"
                onClick={() => {
                  setDropDownShow(true);
                }}
              >
                <div className="flex cursor-pointer m-3">
                  <Label $DropDownUserName>Hola, {currentUser?.name}</Label>
                  <AiOutlineDown className="text-secundary-header-menu" />
                </div>
              </button>
            </div>
            {DropDownShow && (
              <SwitchProfile
                mobileView={mobileView}
                logout={logout}
                setNavMenuOpen={setNavMenuOpen}
                setDropDownShow={setDropDownShow}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

const MenuItem = ({ category, to, location, icon, index }) => (
  <div className="flex justify-between w-full">
    <Link
      className={`${
        location === to ? "text-active-header-menu" : "text-primary-header-menu"
      } hover:text-hover-header-menu w-10/12`}
      to={to}
    >
      {category}
    </Link>
    {icon && (
      <BsChevronRight
        className="mx-1 w-2/12 cursor-pointer"
        onClick={() => {
          const getDropDown = document.getElementById(`dropdown${index}`);
          getDropDown.style.display = "block";
          setTimeout(() => {
            const getDropDown = document.getElementById(`dropdown${index}`);
            getDropDown.style.display = "none";
          }, 5000);
        }}
      />
    )}
  </div>
);

const isUrl = (path) => {
  const regexp =
    /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
  return regexp.test(path);
};

const SubMenuItem = ({ name, to, setNavMenuOpen, location }) =>
  isUrl(to) ? (
    <a
      className={`${
        location === to
          ? "text-primary-header-menu bg-hover-dropdown-link"
          : "text-gray-900"
      } hover:text-hover-header-menu w-10/12 my-0 mr-4 r-8`}
      onClick={() => {
        setNavMenuOpen(false);
      }}
      href={to}
      target="_blank"
      to={to}
      rel="noopener noreferrer"
    >
      {name}
    </a>
  ) : (
    <Link
      $headerLink
      onClick={() => {
        setNavMenuOpen(false);
      }}
      className={`${
        location === to
          ? "text-primary-header-menu bg-hover-dropdown-link"
          : "text-gray-900"
      } hover:text-hover-header-menu w-10/12 my-0 mr-4 r-8`}
      to={to}
    >
      {name}
    </Link>
  );

function MenuCategories({ location, modules }) {
  return (
    <div className="overflow-y-auto flex flex-col ">
      W{" "}
      {modules.map(
        (
          { name, path, isHeaderBarHidden, isInMobileNav, subModules },
          index
        ) => (
          <React.Fragment key={index}>
            {!isHeaderBarHidden && isInMobileNav && (
              <ul
                className="text-primary-nav-menu-link hover:text-hover-nav-menu-link 
              text-base relative inline-block"
              >
                {subModules.length > 0 ? (
                  <>
                    <MenuItem
                      category={name}
                      to={path}
                      location={location}
                      icon={true}
                      index={index}
                      onClick={() => {
                        const getDropDown = document.getElementById(
                          `dropdown${index}`
                        );
                        getDropDown.style.display = "block";
                      }}
                    />
                    <div
                      id={`dropdown${index}`}
                      className="hidden origin-top-left absolute max-h-60 
                      rounded-md shadow-lg bg-primary-nav-menu-color overflow-y-auto
                      ring-1 ring-black z-50 text-gray-900 px-1 py-3"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="menu-button"
                      tabIndex="-1"
                    >
                      {Array.isArray(subModules) &&
                        subModules.map((value, index) =>
                          value.subModule.path.includes("#") ? (
                            <li className="flex flex-col">
                              <Label
                                $DropDownNavMenu
                                key={index}
                                onClick={() => {
                                  window.location.replace(value.subModule.path);
                                }}
                              >
                                {value.subModule.name}
                              </Label>
                            </li>
                          ) : (
                            <div className="flex flex-col">
                              <SubMenuItem
                                location={location}
                                setNavMenuOpen={setNavMenuOpen}
                                name={value.subModule.name}
                                to={value.subModule.path}
                              />
                            </div>
                          )
                        )}
                    </div>
                  </>
                ) : (
                  <MenuItem category={name} to={path} location={location} />
                )}
              </ul>
            )}
          </React.Fragment>
        )
      )}
    </div>
  );
}

function MenuFooter({ logout, unselected, setNavMenuOpen, setDropDownShow }) {
  return (
    <div className="mt-5 mx-0 mb-2 flex justify-start align-bottom">
      <Label
        $DropDownUserName
        onClick={async () => {
          setDropDownShow(false);
          const companyData = await CompanyService.getCompanyData(
            window.location.host
          ).then((data) => data);
          setNavMenuOpen(false);
          unselected(companyData.company);
          logout();
        }}
      >
        Salir
      </Label>
    </div>
  );
}

function NavMenu({
  currentUser,
  isNavMenuOpen,
  setNavMenuOpen,
  logout,
  location,
  modules,
  unselected,
  mobileView,
  clearOrganization,
}) {
  const [DropDownShow, setDropDownShow] = useState(false);

  return (
    <Drawer
      key="left"
      position="relative"
      title={
        <MenuHeader
          currentUser={currentUser}
          logout={logout}
          DropDownShow={DropDownShow}
          setNavMenuOpen={setNavMenuOpen}
          setDropDownShow={setDropDownShow}
          mobileView={mobileView}
        />
      }
      placement="left"
      closable={false}
      onClose={() => setNavMenuOpen(false)}
      visible={isNavMenuOpen}
    >
      <Grid height="100%" rows="7fr 1fr">
        <MenuCategories
          location={location}
          modules={modules}
          setNavMenuOpen={setNavMenuOpen}
        />
        {mobileView && (
          <MenuFooter
            logout={logout}
            unselected={unselected}
            clearOrganization={clearOrganization}
            setDropDownShow={setDropDownShow}
            setNavMenuOpen={setNavMenuOpen}
          />
        )}
      </Grid>
    </Drawer>
  );
}

const mapStateToProps = (state) => ({
  currentUser: selectCurrentUser(state),
  isNavMenuOpen: selectIsNavMenuOpen(state),
  categories: selectCategoriesData(state),
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  setNavMenuOpen: (open) => dispatch(setNavMenuOpen(open)),
  unselected: (company) => dispatch(orgnizacionUnseleted(company)),
  clearOrganization: () => dispatch(clearOrganization()),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(NavMenu);
