import React from "react";

const Lock = () => {
  return (
    <div className="self-start">
      <img
        className="w-4 h-4 xl:w-8 xl:h-8"
        src="https://polpo-assets.s3.amazonaws.com/production/FibSight/Logo/lock.svg"
        alt="lock"
      />
    </div>
  );
};

export default Lock;
