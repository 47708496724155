export const ModalForms = ({ setModal, id }) => {
  const mostrarGlosario = () => {
    switch (id) {
      case 93:
        return (
          <div className="p-4">
            <Header setModal={setModal} />
            <p className="font-bold text-lg mr-2">Empresa según:</p>
            <p className="font-bold underline text-lg mr-2">
              La generación familiar en la que se encuentran (dimensión
              evolutiva de la propiedad):
            </p>
            <p className="mt-4">
              <span className="font-bold">Fundador: </span>
              Control de la propiedad concentrado en un individuo o matrimonio.
              Si existen otros propietarios, tienen acciones Nominales y no
              ejercen autoridad como propietarios. Pretenden obtener capital,
              elegir una estructura de propiedad para la próxima generación,
              etc.
            </p>
            <p className="mt-4">
              <span className="font-bold">Equipo de hermanos: </span>
              Dos o más hermanos tienen control de la propiedad. Pretenden
              establecer un proceso para compartir el control entre
              propietarios, definir la función de los propietarios no empleados,
              atraer y mantener capitales, controlar la orientación partidaria
              de las ramas familiares.
            </p>
            <p className="mt-4">
              <span className="font-bold">Consorcio de primos: </span>
              Existen muchos primos accionistas. Mezcla de propietarios
              empleados o no empleados. Se pretende administrar la empresa
              teniendo en cuenta la complejidad de la familia y los accionistas,
              las diferencias de intereses y necesidades de los primos, etc.
            </p>
          </div>
        );
      case 102:
        return (
          <div className="p-4">
            <Header setModal={setModal} />
            <p className="font-bold text-lg mr-2">Empresa según:</p>
            <p className="font-bold underline text-lg mr-2">
              Tamaño de la familia, tamaño de la empresa familiar
            </p>
            <p className="mt-4">
              <span className="font-bold">Modelo A: </span>
              Familia pequeña, empresa pequeña. Se sitúan las numerosas empresas
              que no han tenido mucho desarrollo en la primera y en la segunda
              generación.
            </p>
            <p className="mt-4">
              <span className="font-bold">Modelo B: </span>
              Familia grande, empresa pequeña. Empresas que, con el transcurrir
              del tiempo, no se desarrollan de manera tan rápida como lo hace la
              familia.
            </p>
            <p className="mt-4">
              <span className="font-bold">Modelo C: </span>
              Familia pequeña, empresa grande. empresas que alcanzan un gran
              tamaño en la primera generación.
            </p>
            <p className="mt-4">
              <span className="font-bold">Modelo D: </span>
              Familia grande, empresa grande. Se encuentran las empresas
              multigeneracionales exitosas.
            </p>
          </div>
        );
      case 103:
        return (
          <div className="p-4">
            <Header setModal={setModal} />
            <p className="font-bold text-lg mr-2">Empresa según:</p>
            <p className="font-bold underline text-lg mr-2">
              Responsabilidades que los miembros de la familia desempeñan
              (Organización).
            </p>
            <p className="mt-4">
              <span className="font-bold">Empresa de trabajo: </span>
              se lleva a cabo lo necesario para que todos los miembros de la
              familia que libremente deseen incorporarse puedan hacerlo, cada
              uno en un puesto acorde con sus capacidades, y con una
              remuneración adecuada a las actividades que realiza.
            </p>
            <p className="mt-4">
              <span className="font-bold">Empresa de dirección: </span>
              se promueve y sólo se permite la incorporación de aquellas
              personas de la familia que posean las condiciones para desempeñar
              responsabilidades de Por responsabilidades de dirección se suele
              entender la dirección general de la empresa, la dirección de
              unidades estratégicas de negocio que requieran la conducción de
              equipos humanos complejos, así como la dirección de funciones para
              las que se precisa una muy elevada especialización técnica.
            </p>
            <p className="mt-4">
              <span className="font-bold">Empresa de gobierno: </span>
              las personas de la familia sólo desempeñan responsabilidades de
              gobierno, y no de dirección, como miembros de los consejos de
              administración del holding familiar y de las empresas filiales y
              participadas por la familia y actúan plenamente como propietarios
              activos
            </p>
            <p className="mt-4">
              <span className="font-bold">
                Empresa de inversiones y emprendimientos:{" "}
              </span>
              es una empresa de gobierno familiar en la que, además, se promueve
              el espíritu emprendedor de sus miembros. En ella se apoya a
              aquellos integrantes de la familia que tengan intención de iniciar
              un nuevo y pro- pio emprendimiento de negocios por medio de
              préstamos, capital fijo o de desarrollo, asesoramiento y consejo,
              etc.
            </p>
            <p className="mt-4">
              <span className="font-bold">Empresa familiar coyuntural: </span>
              responde a la firme intención de los miembros de la familia de
              continuar unidos, aun cuando resulta claramente previsible que por
              circunstancias del entorno competitivo o de la propia familia, se
              tendrá que dejar de ser empresa familiar en un período no muy
              prolongado en el tiempo. La familia está convencida de que la
              venta a terceros o la fusión con otra empresa se realizará de
              forma más beneficiosa si sus miembros continúan unidos, en vez de
              enfrascados en disensiones y luchas.
            </p>
          </div>
        );
      case 104:
        return (
          <div className="p-4">
            <Header setModal={setModal} />
            <p className="font-bold text-lg mt-2 mr-2">Empresa según:</p>
            <p className="font-bold underline text-lg mr-2">
              Crecimiento y evolución de la empresa
            </p>
            <p className="mt-4">
              <span className="font-bold">"Bonsai": </span>
              corresponde a las empresas familiares que prácticamente no han
              cambiado o han cambiado muy poco. A lo largo de su vida estas
              empresas han mantenido un tamaño pequeño, han trabajado los mismos
              productos y han orientado su acción comercial a un nicho de
              clientes similares. Eso sí, gracias a su pequeño número de
              clientes, han sido líderes en las tecnologías de producto y
              servicio que precisaban.
            </p>
            <p className="mt-4">
              <span className="font-bold">"Árbol solitario": </span>
              es el nombre que en este modelo se aplica al tipo de empresas
              familiares que han crecido de manera importante, pero trabajando
              siempre en el mismo sector de actividad empresa- rial y, con
              frecuencia, con una gama de productos restringida, que sólo ha
              evolucionado conforme sus funcionalidades debían adaptarse a los
              cambios en las necesidades de los clientes.
            </p>
            <p className="mt-4">
              <span className="font-bold">"Bosque": </span>
              corresponde a empresas que han tenido un crecimiento moderado
              logrado, en buena parte, gracias a una diversificación casi
              exclusivamente geográfica por medio, muchas veces, de la apertura
              y el desarrollo de filiales en otros países y manteniendo una
              misma gama de productos en la que los principales cambios han
              correspondido a las necesidades de adaptarlos a los
            </p>
            <p className="mt-4">
              <span className="font-bold">"Selva tropical":</span>
              es el nombre que corresponde a las empresas familiares que han
              logrado un elevado crecimiento, diversificando productos, entrando
              en nuevos sectores y mercados geográficos y apoyándose con
              frecuencia en diferentes tipos de alianzas estratégicas.
            </p>
          </div>
        );
      case 105:
        return (
          <div className="p-4">
            <Header setModal={setModal} />
            <p className="font-bold text-lg mt-2 mr-2">Empresa según:</p>
            <p className="font-bold underline text-lg mr-2">
              Dilución de la propiedad
            </p>
            <p className="mt-4">
              <span className="font-bold">Concentrada: </span>
              se refiere a la empresa con un propietario único.
            </p>
            <p className="mt-4">
              <span className="font-bold">Semi concentrada: </span>
              Propiedad se encuentra en manos de unas pocas personas.
            </p>
            <p className="mt-4">
              <span className="font-bold">Diluida: </span>
              Un mayor número de personas poseen propiedad del negocio.
            </p>
          </div>
        );
      case 106:
        return (
          <div className="p-4">
            <Header setModal={setModal} />
            <p className="font-bold text-lg mt-2 mr-2">Empresa según:</p>
            <p className="font-bold underline text-lg mr-2">
              Crecimiento y evolución (Estrategia)
            </p>
            <p className="mt-4">
              <span className="font-bold">Mononegocio: </span>
              Empresa enfocada solamente en un negocio. Va unida a una
              organización funcional, o sea divide a una empresa en funciones
              generales y bien delimitadas unas de otras. Es decir, todos los
              especialistas en marketing pertenecen al departamento de
              marketing, todos los responsables de recursos humanos al de
              recursos humanos, etc.
            </p>
            <p className="mt-4">
              <span className="font-bold">Multinegocio: </span>
              Una empresa con múltiples negocios relacionados. Va unida a una
              organización divisional, lo que quiere decir que la empresa al
              hacerse más grande requiere una estructura más diferenciada y la
              subdivisión es factible de acuerdo con las siguientes áreas de
              actividad:
            </p>
            <div className="mt-4 ml-6">
              <p>• Productos/servicios</p>
              <p>• Público objetivo/mercado</p>
              <p>• Región/zona de venta</p>
              <p></p>
            </div>
            <p className="mt-4">
              <span className="font-bold">Cartera de negocios: </span>
              Una compañía en la que conviven múltiples negocios no
              relacionados, en los que, además, se tienen distintos socios y
              diferentes niveles de participación accionarial, va unida a una
              organización tipo "holding" que se refiere a la combinación de
              ventajas del modelo funcional y el divisional y las reúne en una
              matriz tridimensional. Los puestos de trabajo y los departamentos
              se dividen, por un lado, según las funciones y, por otro, según
              las divisiones. Los diferentes puestos de autoridad se dividen en
              dos dimensiones independientes con los mismos derechos.
            </p>
          </div>
        );
      case 123:
        return (
          <div className="p-4">
            <Header setModal={setModal} />
            <p className="mt-4">
              <span className="font-bold underline text-lg mr-2">
                Cohesión familiar:
              </span>
              Esta dimensión evalúa el grado en que los miembros de la familia
              están separados o conectados entre sí y son capaces de apoyarse
              unos a otros. Se define como el vínculo emocional entre los
              miembros.
            </p>
            <p className="mt-4">
              <span className="font-bold">Familia desligada: </span>
              sus miembros están muy poco involucrados unos con otros, pasan
              poco tiempo juntos y las interacciones entre ellos son escasas.
            </p>
            <p className="mt-4">
              <span className="font-bold">Familia separada: </span>
              existe cierto grado de lealtad y de implicación con los demás,
              incluso hay muestras de afecto. Pero, aun así, se sigue alentando
              la separación personal y el desarrollo individual.
            </p>
            <p className="mt-4">
              <span className="font-bold">Familia unida: </span>
              va un paso más allá en la cercanía e intimidad emocional, pero
              siguen ubicada en un punto saludable. En estos tipos de familias
              se alienta la lealtad y se promueven la expresión del cariño. Se
              considera importante que cada miembro esté implicado con los
              demás, que la familia sea un punto central de interés para todos y
              que se tengan en cuenta todas las opiniones al decidir.
            </p>
            <p className="mt-4">
              <span className="font-bold">Familia aglutinada: </span>
              posee excesiva dependencia y apego. La familia es el interés
              principal, se exige lealtad y las decisiones se toman con base en
              el interés conjunto.
            </p>
          </div>
        );
      case 124:
        return (
          <div className="p-4">
            <Header setModal={setModal} />
            <p className="mt-4">
              <span className="font-bold underline text-lg mr-2">
                Adaptabilidad familiar:
              </span>
              Esta dimensión examina la habilidad del sistema familiar para
              cambiar su estructura de poder, sus roles y reglas de relación en
              respuesta a una demanda situacional o de desarrollo..
            </p>
            <p className="mt-4">
              <span className="font-bold">Familia rígida: </span>
              Se caracteriza por un liderazgo autoritario y fuerte control
              parental, los padres imponen las decisiones; disciplina estricta,
              rígida y su aplicación es severa; roles rígidos y estereotipados;
              las reglas se hacen cumplir estrictamente, no existiendo la
              posibilidad de cambio.
            </p>
            <p className="mt-4">
              <span className="font-bold">Familia estructurada: </span>
              Tiene que ver con un liderazgo, en principio autoritario, siendo
              algunas veces igualitario; control democrático y estable, los
              padres toman las decisiones; disciplina democrática con
              predictibles consecuencias; los roles son estables, pero pueden
              compartirse; las reglas se hacen cumplir firmemente, son pocas las
              que cambian.
            </p>
            <p className="mt-4">
              <span className="font-bold">Familia flexible: </span>
              Identifica un liderazgo igualitario, permite cambios; control
              igual en todos los miembros de la familia, con fluido cambio y hay
              acuerdo en las decisiones; disciplina democrática con
              impredecibles consecuencias; se comparten e intercambian los
              roles; las reglas se hacen cumplir con flexibilidad y algunas
              cambian.
            </p>
            <p className="mt-4">
              <span className="font-bold">Familia caótica: </span>
              Se define por un liderazgo limitado o ineficaz; no existe control;
              disciplina poco severa, habiendo inconsistencia en sus
              consecuencias, las decisiones parentales son impulsivas, falta de
              claridad en los roles y frecuentes cambios en las reglas.
            </p>
          </div>
        );
      default:
        break;
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-400 bg-opacity-50 overflow-y-auto h-full w-full z-50">
      <div className="w-full h-full my-20">
        <div className="mx-auto w-2/4 shadow-lg bg-white border-t-4 border-principal">
          {mostrarGlosario()}
        </div>
      </div>
    </div>
  );
};

const Header = ({ setModal }) => {
  return (
    <div className="w-full h-full flex flex-col justify-between items-center">
      <div className="w-full flex justify-between items-center border-b-2 border-gray-300 pb-2">
        <h2 className="font-semibold text-xl">Glosario</h2>
        <button
          className="text-2xl font-bold text-red-400 hover:text-red-600"
          onClick={() => setModal(false)}
        >
          X
        </button>
      </div>
    </div>
  );
};
