import React from "react";
import PropTypes from "prop-types";
//componet
import { SpinnerButton, InformativeMsg } from "Components";
//icon
import { FiSend } from "react-icons/fi";
//service
import {
  setAllAssigment,
  getAssigmentSpecificResource,
} from "Services/AssigmentService";

const AssigmentStatud = ({
  myAssigments,
  currentUser,
  organizationId,
  setMyAssigment,
  showModal,
  setShowModal,
  allAssigmentInfo,
  setAllAssigmentInfo,
}) => {
  return (
    <div
      className="card self-start glass lg:card-side text-neutral-content h-auto w-full py-3 lg:px-5 lg:py-4 lg:fixed 
  lg:w-vw-10 lg:right-10"
    >
      <div className="flex flex-col w-full">
        <span className="text-white text-3xl text-center font-semibold">
          Avances de asignaciones
        </span>
        <ul className="steps steps-vertical">
          {Array.isArray(myAssigments) &&
            myAssigments.map(({ title, users }, index) => (
              <li
                key={index}
                data-content={users.length === 0 ? "x" : "✓"}
                className={users.length === 0 ? "step" : "step step-primary"}
              >
                {`${title} ${
                  users.length === 0
                    ? ""
                    : users[0].UserHomework?.isSent
                    ? "✔️ Enviada"
                    : "💼  Adjuntada"
                }`}
              </li>
            ))}
        </ul>
        <button
          className={`btn w-10/12 bg-primary-button border-none self-center
          hover:bg-primary-hover-button text-xs md:text-lg lg:text-xs`}
          onClick={async () => {
            const location = window.location.pathname;
            const resourceId = location.split("/")[3];

            const responseData = {
              status: null,
              fetchData: null,
            };

            const { token } = currentUser;
            setAllAssigmentInfo({ loading: true });

            //confirmation is dynamic
            let confirmation = false;
            const assigmentIsNotSent = (currentValue) =>
              currentValue.users?.length !== 0;

            const allAssigmentIsSent = myAssigments.every(assigmentIsNotSent);

            if (allAssigmentIsSent) {
              confirmation = true;
            }
            await setAllAssigment({
              resourceId,
              organizationId,
              token,
              confirmation,
            })
              .then((response) => {
                responseData.status = response.status;
                return response.json();
              })
              .then((data) => (responseData.fetchData = data.message));

            setAllAssigmentInfo({ loading: false });

            if (responseData.status === 409) {
              if (!allAssigmentIsSent) {
                setAllAssigmentInfo({ msg: responseData.fetchData || "" });
                setShowModal(!showModal);
              } else {
                setAllAssigmentInfo({
                  errorMsg: responseData.fetchData,
                });

                setTimeout(() => {
                  setAllAssigmentInfo({
                    succeMsg: null,
                  });
                }, 5000);
              }
            } else {
              if (responseData.status === 200) {
                setAllAssigmentInfo({
                  succeMsg: "Todas sus tareas han sido enviadas con éxito.",
                });

                const currentAssigmentData = await getAssigmentSpecificResource(
                  {
                    resourceId,
                    token,
                    organizationId,
                  }
                ).then((response) => response.json());
                setMyAssigment(currentAssigmentData.homeworks);

                setTimeout(() => {
                  setAllAssigmentInfo({
                    succeMsg: null,
                  });
                }, 5000);
              } else {
                setAllAssigmentInfo({
                  errorMsg: "Ocurrio un error vuelva a intentarlo",
                });

                setTimeout(() => {
                  setAllAssigmentInfo({
                    succeMsg: null,
                  });
                }, 5000);
              }
            }
          }}
        >
          {allAssigmentInfo.loading ? (
            <SpinnerButton />
          ) : (
            <FiSend className="text-3xl px-1" />
          )}
          Entregar todas las Asignaciones
        </button>

        {allAssigmentInfo.succeMsg && (
          <InformativeMsg msg={allAssigmentInfo.succeMsg} type="succeMsg" />
        )}
        {allAssigmentInfo.errorMsg && (
          <InformativeMsg msg={allAssigmentInfo.errorMsg} type="errorMsg" />
        )}
      </div>
    </div>
  );
};

AssigmentStatud.propTypes = {
  myAssigments: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
  organizationId: PropTypes.number.isRequired,
  setMyAssigment: PropTypes.func.isRequired,
};

export default AssigmentStatud;
