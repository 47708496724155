import { ModalForms } from "Components/ModalForms";
import React from "react";
import { useState } from "react";
import { Genograma } from "./Genograma";

const TypeOfQuestion = ({ frameWorkOfQuestion, register, setModal, setIdQuestion }) => {

  const {
    answers,
    answerWithText,
    id,
    order,
    position,
    required,
    setAnswerWithText,
    multipleAnswer,
    setMultipleAnswer,
    family,
    setFamily,
    answerCheckbox,
    setAnswerCheckbox,
    title,
    type,
  } = frameWorkOfQuestion;

  const questionId = id;

  const typeOfQuestion = {
    multiple: "multiple",
    open: "text",
    unique: "unique",
    genograma: "genograma",
  };

  switch (type) {
    case typeOfQuestion.multiple:
      return (
        <div className="w-full pb-4 text-black text-justify">
          <span className="font-medium text-base lg:text-xl">
            {order}) {""} {title} {`${questionId === 123 ? "Consultar glosario" : ""}`}
          </span>
          <div
            className={`flex ${
              position.includes("horizontal") ? "flex-row" : "flex-col"
            } my-2 justify-start`}
          >
            {Array.isArray(answers) &&
              answers.map(({ text, id }, indeX) => (
                <div key={indeX} className="text-black flex flex-row">
                  <label className="label">
                    <input
                      type="checkbox"
                      id={`checkbox${id}`}
                      onClick={() => {
                        if (
                          multipleAnswer.find(
                            (answer) => answer.questionId === id
                          )
                        ) {
                          const newAnswer = multipleAnswer.filter(
                            (answer) => answer.questionId !== id
                          );
                          setMultipleAnswer(newAnswer);
                        } else {
                          setMultipleAnswer([
                            ...multipleAnswer,
                            {
                              questionId: questionId,
                              answer: `${id}`,
                            },
                          ]);
                        }
                      }}
                      value={id}
                      className="checkbox checkbox-sm lg:checkbox-md checkbox-primary"
                      {...register(`checkbox${id}`)}
                    />
                    <span className="label-text text-black mx-3 text-base lg:text-xl">
                      {text}
                    </span>
                  </label>
                </div>
              ))}
          </div>
        </div>
      );
    case typeOfQuestion.unique:
      return (
        <div className="w-full pb-4 text-black text-justify">
          <span className="font-medium text-base lg:text-xl">
            {order}) {""} {title}
            {
              ((questionId === 93) ||
              (questionId === 102) ||
              (questionId === 103) ||
              (questionId === 104) ||
              (questionId === 105) ||
              (questionId === 106) ||
              (questionId === 123) ||
              (questionId === 124)) && (
                <button
                  type="button"
                  className="ml-2 text-blue-600 cursor-pointer"
                  onClick={() => {
                    setModal(true);
                    setIdQuestion(questionId);
                  }}
                >
                  Consultar glosario
                </button>
              )
            }
            {required && <span className="text-red-500">*</span>}
          </span>
          <div
            className={`flex ${
              position.includes("horizontal")
                ? "flex-row justify-between items-baseline py-4 lg:px-6 text-justify"
                : "flex-col"
            }`}
          >
            {position.includes("horizontal") && (
              <span className="text-sm font-medium lg:text-xl">Muy poco</span>
            )}
            {Array.isArray(answers) &&
              answers.map(({ text, id }, indeX) => (
                <div key={indeX} className="text-black flex flex-row">
                  <label
                    className={`label ${
                      position.includes("horizontal") && "flex flex-col-reverse"
                    }`}
                  >
                    <input
                      type="radio"
                      id={`checkbox${frameWorkOfQuestion.id}`}
                      value={id}
                      onClick={() => {
                        setAnswerCheckbox([
                          ...answerCheckbox,
                          {
                            questionId: questionId,
                            answer: `${id}`,
                          },
                        ]);
                      }}
                      className="checkbox-primary radio-sm lg:radio-md"
                      {...register(`checkbox${frameWorkOfQuestion.id}`, {
                        required,
                      })}
                    />
                    <span className="label-text text-black mx-3 text-base lg:text-xl">
                      {text}
                    </span>
                  </label>
                </div>
              ))}
            {position.includes("horizontal") && (
              <span className="text-sm font-medium lg:text-xl">
                Muy definido
              </span>
            )}
          </div>
        </div>
      );
    case typeOfQuestion.open:
      return (
        <div className="w-full flex flex-col gap-5 pb-4 text-black text-justify">
          <span className="font-medium text-base xl:text-xl">
            {order}) {""} {title} {`${questionId === 124 ? "Consultar glosario" : ""}`}
            {required && <span className="text-red-500">*</span>}
          </span>
          <input
            type="text"
            id={`checkbox${id}`}
            value={
              answerWithText.find((answer) => answer.questionId === id)
                ? answerWithText.find((answer) => answer.questionId === id)
                    .answer
                : ""
            }
            onChange={({ target }) => {
              if (answerWithText.find((answer) => answer.questionId === id)) {
                const newAnswer = answerWithText.map((answer) => {
                  if (answer.questionId === id) {
                    return {
                      ...answer,
                      answer: target.value,
                    };
                  }
                  return answer;
                });
                setAnswerWithText(newAnswer);
              } else {
                setAnswerWithText([
                  ...answerWithText,
                  {
                    questionId: id,
                    answer: target.value,
                  },
                ]);
              }
            }}
            placeholder="Responda aca..."
            style={{ background: "#f4f4ff" }}
            required={required}
            className="xl:py-3 py-2 px-3 xl:px-10 rounded-lg text-base xl:text-xl"
          />
        </div>
      );
    case typeOfQuestion.genograma:
      return (
        <Genograma
          title={title}
          order={order}
          position={position}
          answers={answers}
          register={register}
          family={family}
          setFamily={setFamily}
        />
      );
    default:
      return (
        <div className="w-full text-black text-justify">
          La pregunta no cargo correctamente
        </div>
      );
    
    }
};

const Question = ({ frameWorkOfQuestion, register }) => {

  const [modal, setModal] = useState(false);
  const [idQuestion, setIdQuestion] = useState(null);
  return (
    <>
      <TypeOfQuestion
        register={register}
        frameWorkOfQuestion={frameWorkOfQuestion}
        setModal={setModal}
        setIdQuestion={setIdQuestion}
      />
      {modal && <ModalForms setModal={setModal} id={idQuestion} />}
    </>
  );
};

export default Question;
