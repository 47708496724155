import React from "react";

const PlanIcon = ({ plan }) => {
  switch (plan) {
    case "Oro":
      return (
        <img
          src="https://polpo-assets.s3.amazonaws.com/production/FibSight/Logo/Gold.svg"
          alt="gold"
        />
      );
    case "Diamante":
      return (
        <img
          src="https://polpo-assets.s3.amazonaws.com/production/FibSight/Logo/Diamont.svg"
          alt="diamont"
        />
      );
    default:
      return (
        <img
          src="https://polpo-assets.s3.amazonaws.com/production/FibSight/Logo/Gold.svg"
          alt="gold"
        />
      );
  }
};
const PlanInfo = ({ plans }) => {
  return (
    <div className="flex flex-col px-8">
      <div className="flex items-baseline gap-3">
        <PlanIcon plan={plans} />
        <span className="xl:text-2xl uppercase text-white">
          Contenido exclusivo
        </span>
      </div>
      <a href="https://fibsight.com/planes/" className="xl:text-base underline self-end" target="_blank" rel="noopener noreferrer">
        Ver planes
      </a>
    </div>
  );
};

export default PlanInfo;
