//Libs
import moment from "moment";
//Services
import Mixpanel from "Services/MixPanelService";
import FetchPlus from "Services/FetchPlus";
//Config
import { API_URL, CLOUD_STORAGE_URL, APPLICATION_NAME } from "Config/config";
//Users
const usersBase = `${API_URL}`;

/**
 *
 * @param email
 * @param password
 *
 * @Return
 * userData | false
 */
export async function signUp(newUser) {
  let randomString = (Math.random() + 1).toString(36).substring(7);

  try {
    const response = await fetch(`${usersBase}auth/sign-up`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: newUser.name,
        firstLastName: newUser.firstLastName,
        secondLastName: newUser.secondLastName,
        password: "endo2022",
        dni: `${randomString}endo2022`,
        phone: newUser.phone,
        email: newUser.email,
        professions: ["187"],
        professionalCode: newUser.professionalCode || null,
        organizations: ["18"],
        companyId: newUser.companyId,
        interests: ["47"],
        isSponsor: false,
        phoneRecovery: null,
        profilePhotoUrl: null,
        emailRecovery: null,
        bio: "",
        countryId: newUser.countryId,
      }),
    });
    if (response.status === 409) {
      let message = await response.json();
      message = message.message;

      return {
        response: message,
        status: 409,
      };
    }
    if (response.status !== 200) {
      Mixpanel.track(Mixpanel.TYPES.UNSUCCESSFUL_SIGNUP);
      return false;
    }
    const { user, token } = await response.json();

    const isSponsor = user.userCompany[0].isSponsor;
    const email = user.userCompany[0].email;
    const permission = user.organizations[0].permissions;
    const profileImageUser = user.userCompany[0].profilePhotoUrl || null;

    const profile =
      user.organizations[0].UserOrganization?.roleName || "Silver";

    //destructure the user
    const {
      id,
      name,
      firstLastName,
      secondLastName,
      professionalCode,
      countryId,
      organizations,
    } = user;

    Mixpanel.identify(id);
    Mixpanel.track(Mixpanel.TYPES.SUCCESSFUL_SIGNUP);
    Mixpanel.people.set({
      $first_name: name,
      $last_name: firstLastName,
      professionalCode,
    });

    return {
      token,
      id,
      name,
      countryId,
      organizations,
      secondLastName,
      firstLastName,
      email,
      isSponsor,
      profileImageUser,
      permission,
      profile,
    };
  } catch (err) {
    Mixpanel.track(Mixpanel.TYPES.UNSUCCESSFUL_SIGNUP);
    throw err;
  }
}

export async function login(email, password, companyId) {
  try {
    const response = await fetch(`${usersBase}auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
        companyId,
      }),
    });

    if (response.status !== 200) {
      Mixpanel.track(Mixpanel.TYPES.UNSUCCESSFUL_LOGIN, {
        email,
      });
      return false;
    }
    const { token, user } = await response.json();
    const permission = user.organizations[0].permissions;
    const isSponsor = user.userCompany[0].isSponsor;
    const profileImageUser = user.userCompany[0].profilePhotoUrl || null;
    //temporal code this save the perfil or role
    const role = user.organizations[0]?.roleName;

    //destructure the user
    const {
      id,
      name,
      firstLastName,
      secondLastName,
      professionalCode,
      countryId,
      organizations,
    } = user;

    Mixpanel.identify(id);
    Mixpanel.track(Mixpanel.TYPES.SUCCESSFUL_LOGIN);
    Mixpanel.people.set({
      $email: email,
      $first_name: name,
      $last_name: firstLastName,
      professionalCode,
    });

    return {
      token,
      id,
      name,
      countryId,
      organizations,
      secondLastName,
      firstLastName,
      email,
      isSponsor,
      profileImageUser,
      permission,
      role,
    };
  } catch (err) {
    Mixpanel.track(Mixpanel.TYPES.UNSUCCESSFUL_LOGIN, {
      email,
    });
    throw err;
  }
}

export async function sendEmailForgotPassword(email, companyId) {
  const response = await fetch(`${usersBase}recovery/code`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
      companyId,
    }),
  });
  return response.status === 200;
}

export async function sendCodeConfirmationToRecoveryPassword(companyId, code) {
  const response = await fetch(`${usersBase}recovery/check-code`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      companyId,
      recoveryCode: code,
    }),
  });
  const token = await response.json();
  return { status: response.status === 200, token: token };
}

export async function resetPassword(token, companyId, newPassword) {
  try {
    const response = await fetch(`${usersBase}recovery/update-password`, {
      method: "PUT",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        companyId,
        newPassword,
      }),
    });

    Mixpanel.track(Mixpanel.TYPES.SUCCESSFUL_RESET_PASSWORD);
    return response.status === 200;
  } catch (err) {
    Mixpanel.track(Mixpanel.TYPES.UNSUCCESSFUL_RESET_PASSWORD);
    throw err;
  }
}

export async function saveProfilePhoto(
  token,
  photo,
  sendMessage,
  organizationId
) {
  if (!token || !photo || !organizationId) {
    sendMessage({
      body: "Se necesita un token y una foto para realizar esta acción",
    });
    return;
  }
  const formData = new FormData();
  formData.append("file", photo); //"file" is a Keyword. No change
  const options = {
    method: "PUT",
    headers: {
      Authorization: token,
    },
    body: formData,
  };

  return FetchPlus(
    `${usersBase}organizations/${organizationId}/user/profile-photo`,
    options
  )
    .then((res) => res.userCompany.profilePhotoUrl)
    .catch((errorCode) => {
      if (errorCode === 401 || errorCode === 403) {
        sendMessage({
          body: "La cuenta que posee no puede realizar esta acción",
        });
      } else {
        sendMessage({
          body: "Hubo un problema al subir la foto. Por favor vuelva a intentarlo",
        });
      }
      return false;
    });
}
// config user services
// This is a get specifit user information
export async function getSpecificUserCurrentInfo(token, organizationId) {
  if (!token || !organizationId) {
    return;
  }

  const options = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  // const currenUserInfo = await FetchPlus(
  //   `${usersBase}organizations/${organizationId}/user/info`,
  //   options
  // );
  // return currenUserInfo || {};

  return FetchPlus(
    `${usersBase}organizations/${organizationId}/user/info`,
    options
  )
    .then((data) => data)
    .catch((err) => {
      if (err === 401) {
        return err;
      }
      console.log(err);
    });
}
// this update the user information
export async function updateCurrentUserInfo(
  token,
  organizationId,
  { bio, firstLastName, secondLastName, phone, name, professionalCode }
) {
  if (!token || !organizationId) {
    return;
  }
  const options = {
    method: "PUT",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      bio,
      firstLastName,
      secondLastName,
      phone,
      name,
      professionalCode,
    }),
  };

  return FetchPlus(
    `${usersBase}organizations/${organizationId}/user/info`,
    options
  )
    .then((currentUser) => currentUser)
    .catch((err) => {
      return err;
    });
}
//get profession with organization
export async function getOrganizationProfession(organizationId) {
  if (!organizationId) {
    return;
  }
  return FetchPlus(`${usersBase}organizations/${organizationId}/professions`)
    .then((professions) => professions.organization.professions)
    .catch((err) => {
      console.log(err);
    });
}
//get interest with organization
export async function getOrganizationInteres(organizationId) {
  if (!organizationId) {
    return;
  }
  return FetchPlus(`${usersBase}organizations/${organizationId}/interests`)
    .then((interests) => interests.organization.interests)
    .catch((err) => {
      console.log(err);
    });
}
// update interest user
export async function setNewInterests(token, organizationId, interests) {
  if (!token || !organizationId || !interests) {
    return;
  }
  const options = {
    method: "PUT",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      interests,
    }),
  };
  return FetchPlus(
    `${usersBase}organizations/${organizationId}/user/info/interests`,
    options
  )
    .then((interests) => interests)
    .catch((err) => {
      console.log(err);
    });
}
//update new password
export async function updatePassword(token, organizationId, newPassword) {
  if (!token || !organizationId || !newPassword) {
    return;
  }
  const options = {
    method: "PUT",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      newPassword,
    }),
  };
  return FetchPlus(
    `${usersBase}organizations/${organizationId}/user/change-password`,
    options
  )
    .then((newPassword) => newPassword)
    .catch((err) => {
      console.log(err);
    });
}

//update professions user
export async function setNewProfessions(token, organizationId, professions) {
  if (!token || !organizationId || !professions) {
    return;
  }
  const options = {
    method: "PUT",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      professions,
    }),
  };
  return FetchPlus(
    `${usersBase}organizations/${organizationId}/user/info/professions`,
    options
  )
    .then((interests) => interests)
    .catch((err) => {
      console.log(err);
    });
}
export function checkImage(path) {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve({ path, status: "ok" });
    img.onerror = () => resolve({ path, status: "error" });

    img.src = path;
  });
}

export const getProfilePhotoDefault = async () => {
  const defaultPhoto = CLOUD_STORAGE_URL.concat(
    `/${process.env.NODE_ENV}/${APPLICATION_NAME}/sponsors/avatars/default-avatar.png`,
    "?",
    moment().format("YYMMDDHHmmss")
  );
  return defaultPhoto;
};
