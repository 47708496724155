import styled, { css } from "styled-components";
import { DEVICE_IS_MOBILE, MOBILE_VIEWPORT_SIZE } from "Config/config";

export default styled.span`
  display: inline;
  vertical-align: bottom;
  line-height: 1.25vw;
  font-size: 1.4vw;
  margin-left: 3.7vw;
  /* margin-bottom: 0.5em; */
  color: white;
  @media screen and (max-width: ${MOBILE_VIEWPORT_SIZE}px) {
    font-size: 1.3em;
    line-height: 1.3em;
    font-weight: bolder;
    /* margin-top: 2em; */
  }
  ${() =>
    DEVICE_IS_MOBILE &&
    css`
      font-size: 1.3em;
      line-height: 1.3em;
      font-weight: bolder;
      margin-top: 2em;
    `}
`;
