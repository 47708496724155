//Config
import { API_URL } from "Config/config";
import FetchPlus from "./FetchPlus";

export const questionsService = async ({ token, id }) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return FetchPlus(`${API_URL}autoevaluations/resources/${id}`, options)
    .then((response) => response)
    .catch((error) => error);
};

export const sendAnswerService = async ({
  evaluationFormId,
  answers,
  familyMembers,
  token,
}) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({
      evaluationFormId,
      answers,
      familyMembers,
    }),
  };
  return FetchPlus(`${API_URL}reports/save-evaluation`, options)
    .then((response) => response)
    .catch((error) => error);
};
