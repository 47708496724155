//Libs
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import tw from "tailwind-styled-components";

const MenuButton = tw(FontAwesomeIcon)`
  w-4
  h-8
  m-2
  inline
  text-2xl
  cursor-pointer
  lg:text-active-header-menu
  max-md:text-white
  `;

export default MenuButton;
