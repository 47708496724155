//L
import React, { useState } from "react";
import { withRouter } from "react-router";
import { ROUTES } from "Config/config";
//Actions
import { logout, setNavMenuOpen } from "Redux/User/user-actions";
//select
import { selectIsOpenStory } from "Redux/Stories/stories-selectors";
import {
  selectedOrganizationStyle,
  selectOrganizationMembership,
} from "Redux/Organization/organization-select";
import { connect } from "react-redux";
import { compose } from "redux";
//Components
import { faBars } from "@fortawesome/free-solid-svg-icons";
import StyledDesktopHeader from "./StyledDesktopHeader";

//library
import {
  ProfilePhoto,
  Logo,
  ResourceUpload,
  SwitchProfile,
  Link,
} from "Components";
import Label from "./Label";
import MenuButton from "./MenuButton";
import StyledMobileHeader from "./StyledMobileHeader";
//React Icon Component
import { AiOutlineDown } from "react-icons/ai";
import { SubMenuItem } from "./SubMenuItem";

function DesktopHeader({
  currentRoute,
  currentUser,
  logout,
  memberhip,
  mobileView,
  modules,
  opaqueNavbar,
  orgStyle,
  roles,
  scrollUp,
  setNavMenuOpen,
  DropDownShow,
  setDropDownShow,
}) {
  return currentUser?.role === "Administrador" ? (
    <div
      className="h-full flex justify-between items-center sticky
      left-0 bg-primary-mobile-header z-50 max-md:h-11 text-active-header-menu px-8"
      opaqueNavbar={opaqueNavbar}
      scrollUp={scrollUp}
      onMouseLeave={() => setDropDownShow(false)}
    >
      <Logo
        organizationLogo={orgStyle.polpoflixlogo}
        className="self-center "
      />
      <div className="flex">
        <ResourceUpload id="profilePhoto">
          <ProfilePhoto
            profilePhotoSrc={currentUser?.profileImageUser}
            containerStyle={{
              right: "5px",
              bottom: "2px",
            }}
            cameraStyle={{
              left: "30px",
              bottom: "0",
              fontSize: "1rem",
            }}
          />
        </ResourceUpload>
        <div className="relative inline-block text-left">
          <div>
            <button
              type="button"
              className={`inline-flex justify-center text-primary-header-menu hover:text-hover-header-menu`}
              id="menu-button"
              aria-expanded="true"
              aria-haspopup="true"
              onMouseOver={() => setDropDownShow(true)}
              onBlur={() => setDropDownShow(false)}
            >
              <div className="flex cursor-pointer m-3">
                <Label $DropDownUserName>Hola, {currentUser?.name}</Label>
                <AiOutlineDown className="text-secundary-header-menu" />
              </div>
            </button>
          </div>
          {DropDownShow && (
            <SwitchProfile
              roles={roles}
              mobileView={mobileView}
              logout={logout}
              setDropDownShow={setDropDownShow}
              DropDownShow={DropDownShow}
            />
          )}
        </div>
      </div>
    </div>
  ) : (
    <StyledDesktopHeader
      opaqueNavbar={opaqueNavbar}
      scrollUp={scrollUp}
      onMouseLeave={() => setDropDownShow(false)}
    >
      <div className="flex items-center">
        <Logo organizationLogo={orgStyle.polpoflixlogo} />
        {modules.map(
          (
            { path, name, isHeaderBarHidden, isInDesktopNav, subModules },
            index
          ) =>
            !isHeaderBarHidden &&
            isInDesktopNav &&
            (path && subModules.length > 0 ? (
              <div key={index} className="relative inline-block text-left">
                <div>
                  <button
                    type="button"
                    className={`inline-flex justify-center text-primary-header-menu hover:text-hover-header-menu`}
                    id="menu-button"
                    aria-expanded="true"
                    aria-haspopup="true"
                    onMouseOver={() => setDropDownShow(true)}
                  >
                    <Link
                      className={`${
                        currentRoute === path
                          ? "text-active-header-menu"
                          : "text-primary-header-menu"
                      } hover:text-hover-header-menu`}
                      to={path}
                    >
                      {name}
                    </Link>
                    <svg
                      className="mr-2 h-5 w-5 "
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div
                  id={`dropdown${index}`}
                  className="hidden origin-top-right absolute
                   right-0 mt-6 w-56 rounded-md shadow-lg bg-primary-dropdown ring-1 ring-black
                   ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="menu-button"
                  tabIndex="-1"
                >
                  <div
                    className="py-1"
                    role="none"
                    onMouseLeave={() => setDropDownShow(false)}
                  >
                    <SubMenuItem
                      currentRoute={currentRoute}
                      SubMenuItem={subModules}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <Link
                key={index}
                className={`${
                  currentRoute === path
                    ? "text-active-header-menu"
                    : "text-primary-header-menu"
                } hover:text-hover-header-menu mr-3`}
                to={path}
              >
                {name}
              </Link>
            ))
        )}
      </div>
      <div className="flex">
        {memberhip?.settings && (
          <img
            src={memberhip?.settings.logo}
            className="h-8 px-3 self-center"
            alt="membershipLogo"
          />
        )}
        <ResourceUpload id="profilePhoto">
          <ProfilePhoto
            profilePhotoSrc={currentUser?.profileImageUser}
            containerStyle={{
              right: "5px",
              bottom: "2px",
            }}
            cameraStyle={{
              left: "30px",
              bottom: "0",
              fontSize: "1rem",
            }}
          />
        </ResourceUpload>
        <div className="relative inline-block text-left">
          <div>
            <button
              type="button"
              className={`inline-flex justify-center text-primary-header-menu hover:text-hover-header-menu`}
              id="menu-button"
              aria-expanded="true"
              aria-haspopup="true"
              onMouseOver={() => setDropDownShow(true)}
              onBlur={() => setDropDownShow(false)}
            >
              <div className="flex cursor-pointer m-3">
                <Label $DropDownUserName>Hola, {currentUser?.name}</Label>
                <AiOutlineDown className="text-secundary-header-menu" />
              </div>
            </button>
          </div>
          {DropDownShow && (
            <SwitchProfile
              roles={roles}
              setDropDownShow={setDropDownShow}
              DropDownShow={DropDownShow}
              mobileView={mobileView}
              logout={logout}
            />
          )}
        </div>
      </div>
    </StyledDesktopHeader>
  );
}

function MobileHeader({
  memberhip,
  orgStyle,
  scrollUp,
  setNavMenuOpen,
  currentUser,
}) {
  return (
    <StyledMobileHeader className="" scrollUp={scrollUp}>
      <MenuButton icon={faBars} onClick={() => setNavMenuOpen(true)} />
      {memberhip?.settings && currentUser?.role !== "Administrador" ? (
        <Link to={ROUTES.HOME}>
          <img
            src={memberhip?.settings.logo}
            className="h-8 px-1 self-center"
            alt="membershipLogo"
          />
        </Link>
      ) : (
        <Logo organizationLogo={orgStyle.polpoflixlogo} />
      )}
    </StyledMobileHeader>
  );
}

function Header({
  currentRoute,
  currentUser,
  isOpenStory,
  logout,
  memberhip,
  mobileView,
  modules,
  opaqueNavbar,
  orgStyle,
  roles,
  scrollUp,
  setNavMenuOpen,
}) {
  const [DropDownShow, setDropDownShow] = useState(false);
  return mobileView ? (
    !isOpenStory && (
      <MobileHeader
        currentUser={currentUser}
        setNavMenuOpen={setNavMenuOpen}
        scrollUp={scrollUp}
        orgStyle={orgStyle}
        memberhip={memberhip}
      />
    )
  ) : (
    <DesktopHeader
      mobileView={mobileView}
      currentUser={currentUser}
      modules={modules}
      roles={roles}
      opaqueNavbar={opaqueNavbar}
      scrollUp={scrollUp}
      logout={logout}
      currentRoute={currentRoute}
      orgStyle={orgStyle}
      memberhip={memberhip}
      setNavMenuOpen={setNavMenuOpen}
      setDropDownShow={setDropDownShow}
      DropDownShow={DropDownShow}
    />
  );
}

const mapStateToProps = (state) => ({
  isOpenStory: selectIsOpenStory(state),
  orgStyle: selectedOrganizationStyle(state),
  memberhip: selectOrganizationMembership(state),
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  setNavMenuOpen: (open) => dispatch(setNavMenuOpen(open)),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Header);
