import React from "react";
//component
import { FiAlertCircle } from "react-icons/fi";
import { AiOutlineCheckCircle } from "react-icons/ai";
import PropTypes from "prop-types";

const InformativeMsg = ({ msg, type }) => {
  return msg && type === "succeMsg" ? (
    <div className="py-1 text-left alert alert-success">
      <div className="flex-1">
        <AiOutlineCheckCircle className="w-6 h-6 mx-2 stroke-current" />
        <span className="items-start font-semibold">{msg}</span>
      </div>
    </div>
  ) : (
    <div className="alert alert-error py-2">
      <div className="flex items-center justify-center ">
        <FiAlertCircle className="w-6 h-6 mx-2 stroke-current" />
        <span>{msg}</span>
      </div>
    </div>
  );
};

InformativeMsg.propTypes = {
  msg: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};
export default InformativeMsg;
