import React, { useEffect, useState } from "react";
//ASSETS
// import logo from "Assets/Logo.png";
//Service
import { questionsService, sendAnswerService } from "Services/QuestionService";
import { CompanyService } from "Services/CompanyService";
//Component
import Question from "Components/Question/Question";
import {
  SpinnerButton,
  InformativeMsg,
  Modal,
  Wrapper,
  CustomButton,
} from "Components";
import { useForm } from "react-hook-form";
//router
import { ROUTES } from "Config/config";
import { useParams, useHistory } from "react-router";
//Redux
import { connect } from "react-redux";
// USER REDUX
import { selectCurrentUser } from "Redux/User/user-selectors";
import { logout } from "Redux/User/user-actions";
// DCC REDUX
import {
  orgnizacionUnseleted,
  dccLogout,
} from "Redux/DynamicConfigCompany/dcc-action";
// ORGANIZATION
import { clearOrganization } from "Redux/Organization/organizarion-action";

const Test = ({
  modalShow,
  questionUtil,
  setModalShow,
  typeOfTest,
  useForm,
  history,
}) => {
  const { handleSubmit, register } = useForm;
  const {
    answerWithText,
    description,
    infoMsg,
    isLoading,
    name,
    questions,
    sendAnswer,
    setAnswerWithText,
    multipleAnswer,
    setMultipleAnswer,
    family,
    setFamily,
    answerCheckbox,
    setAnswerCheckbox,
    // totalSeconds,
    // setCurrentTime,
    // currentTime,
  } = questionUtil;
  const type = {
    finalTest: "finalTest",
    singleTest: "singleTest",
  };

  switch (typeOfTest) {
    case type.finalTest:
      return (
        <div className="min-h-screen w-screen flex flex-col justify-center px-8 lg:px-20  xl:px-72 py-20 bg-form-background bg-cover bg-center">
          <Modal
            visible={modalShow}
            onCancel={() => {
              setModalShow(false);
            }}
            footer={null}
          >
            <Wrapper
              display="flex"
              flexDirection="justify-center"
              position="relative"
              textAlign="center"
              padding="30px"
            >
              <div className="flex flex-col justify-center items-center ">
                <div className="self-center my-2">
                  <img
                    src={
                      "https://polpo-assets.s3.amazonaws.com/production/FibSight/Logo/logo-figsight.png"
                    }
                    alt="logo"
                    className="w-40"
                  />
                </div>
                <span className="text-white text-lg mb-5">
                  ¡Gracias por completar tu autoevaluación de FIBSIGHT!
                </span>
                <CustomButton $singbutton onClick={() => history.push("/")}>
                  Regresar
                </CustomButton>
              </div>
            </Wrapper>
          </Modal>
          <div className="bg-white rounded-xl h-full w-full flex flex-col justify-center py-28 px-6 lg:px-14 xl:px-44">
            <div className="self-center">
              <img
                src={
                  "https://polpo-assets.s3.amazonaws.com/production/FibSight/Logo/logo-asocar.png"
                }
                alt="logo"
                className="w-40"
              />
            </div>
            <div className="flex flex-col w-full">
              <span className="text-quiz-title text-xl lg:text-2xl xl:text-4xl font-medium text-center">
                <br />
                {`${name}`}
              </span>
              {description && (
                <>
                  <span className="text-black text-base lg:text-lg font-semibold text-justify">
                    Descripcion
                  </span>
                  <span className=" my-8 text-base lg:text-lg text-justify">
                    {description}
                  </span>
                </>
              )}
            </div>
            <form onSubmit={handleSubmit(sendAnswer)}>
              {questions.map(
                (
                  {
                    answers,
                    id,
                    order,
                    position,
                    required,
                    title,
                    typeQuestion,
                  },
                  index
                ) => (
                  <Question
                    key={index}
                    frameWorkOfQuestion={{
                      answers,
                      answerWithText,
                      id,
                      order,
                      position: position?.name,
                      required,
                      setAnswerWithText,
                      multipleAnswer,
                      setMultipleAnswer,
                      family,
                      setFamily,
                      answerCheckbox,
                      setAnswerCheckbox,
                      title,
                      type: typeQuestion?.name,
                    }}
                    register={register}
                  />
                )
              )}
              <div className="w-full flex justify-center lg:justify-start">
                <button
                  className="bg-primary-button hover:bg-primary-hover-button text-white font-semibold text-base rounded-md py-2 px-20"
                  type="submit"
                >
                  {isLoading ? (
                    <div className="flex gap-2">
                      <SpinnerButton />
                      <span className="text-white">Guardando...</span>
                    </div>
                  ) : (
                    "Enviar"
                  )}
                </button>
              </div>
              {infoMsg.success && (
                <InformativeMsg type="succeMsg" msg={infoMsg.success} />
              )}
              {infoMsg.error && (
                <InformativeMsg type="error" msg={infoMsg.error} />
              )}
            </form>
          </div>
        </div>
      );
    default:
      break;
  }
};

const QuizView = ({
  clearOrganization,
  unselected,
  dccLogoutCompany,
  currentUser,
}) => {
  const history = useHistory();
  const [answerWithText, setAnswerWithText] = useState([]);
  const [answerCheckbox, setAnswerCheckbox] = useState([]);
  const [multipleAnswer, setMultipleAnswer] = useState([]);
  const [evaluation, setEvaluation] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [currentTime, setCurrentTime] = useState();
  const [modalShow, setModalShow] = useState(false);

  const [family, setFamily] = useState([]);

  const [infoMsg, setInfoMsg] = useState({
    error: "",
    success: "",
  });
  const { register, handleSubmit } = useForm();
  const { type, resourceId } = useParams();

  useEffect(() => {
    const getQuestions = async () => {
      const { token } = currentUser;
      const evaluation = await questionsService({ token, id: resourceId }).then(
        (response) => response
      );

      console.log(evaluation);

      setEvaluation(evaluation.evaluation);
    };
    getQuestions();
  }, [currentUser, resourceId]);

  const sendAnswer = async () => {
    setIsLoading(true);

    const { token } = currentUser;

    let answersD = [...answerCheckbox, ...answerWithText, ...multipleAnswer];
    const answers = answersD.sort((a, b) => a.questionId - b.questionId);

    const familyMembers = family?.map((member) => {
      return {
        key: member.key,
        n: member.name,
        s: member.gender,
        m: member.mother,
        f: member.father,
        ux: member.relationship,
        a: [
          member.familyGeneration,
          member.TypeOfStudy,
          member.health,
          member.job,
          member.lifeStatus,
        ],
      };
    });

    const body = {
      evaluationFormId: evaluation.evaluationForms[0].id,
      answers,
      familyMembers,
      token,
    };

    const response = await sendAnswerService(body).then((rest) => rest);

    if (response.success) {
      setInfoMsg({
        success: "Su respuestas fueron enviada correctamente",
      });
      setModalShow(true);

      setTimeout(() => {
        setInfoMsg({
          error: "",
          success: "",
        });
      }, 5000);
    }

    if (!response.success) {
      if (response === 409) {
        setInfoMsg({
          error: "Ya has respondido esta evaluación",
        });
        return;
      }

      setInfoMsg({
        error: "Ha ocurrido un error, intenta nuevamente",
      });

      setTimeout(() => {
        setInfoMsg({
          error: "",
          success: "",
        });
      }, 5000);
    }

    if (response === 401) {
      setInfoMsg({
        error: "Su Token a expirado.",
      });

      setTimeout(async () => {
        const companyData = await CompanyService.getCompanyData(
          window.location.host
        ).then((data) => data);
        clearOrganization();
        dccLogoutCompany();
        unselected(companyData.company);
        logout();
        history.push(ROUTES.SIGNIN);

        setInfoMsg({
          error: "",
          success: "",
        });
      }, 5000);
    }
    setIsLoading(false);
  };
  return Array.isArray(evaluation?.questions) ? (
    <Test
      questionUtil={{
        answerWithText,
        description: evaluation.description,
        infoMsg,
        isLoading,
        name: evaluation.name,
        questions: evaluation.questions,
        sendAnswer,
        setAnswerWithText,
        multipleAnswer,
        setMultipleAnswer,
        family,
        setFamily,
        answerCheckbox,
        setAnswerCheckbox,
        totalSeconds: evaluation.time,
        setCurrentTime,
        currentTime,
      }}
      typeOfTest={type}
      setModalShow={setModalShow}
      history={history}
      modalShow={modalShow}
      useForm={{ handleSubmit, register }}
    />
  ) : (
    <div className="min-h-screen w-full flex justify-center items-center">
      <div className="flex flex-col gap-5 p-10 bg-white rounded-xl w-2/6">
        <div className="self-center my-2">
          <img
            src={
              "https://polpo-assets.s3.amazonaws.com/production/FibSight/Logo/logo-asocar.png"
            }
            alt="logo"
            className="w-40"
          />
        </div>
        <span className="text-quiz-title font-medium text-center">
          Gracias por tu participación. Tu autoevaluación ha sido registrada con
          éxito. No es necesario completarla nuevamente en este momento. Si
          tienes alguna pregunta o necesitas más información, no dudes en
          ponerte en contacto con nosotros. ¡Apreciamos tu compromiso!
        </span>
        <CustomButton $singbutton onClick={() => history.push("/")}>
          Regresar
        </CustomButton>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: selectCurrentUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  clearOrganization: () => dispatch(clearOrganization()),
  dccLogoutCompany: () => dispatch(dccLogout()),
  unselected: (company) => dispatch(orgnizacionUnseleted(company)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuizView);
