import React, { useEffect } from "react";
import { useState } from "react";

export const Genograma = ({
  order,
  title,
  position,
  answers,
  register,
  family,
  setFamily,
}) => {
  const [mothers, setMothers] = useState([]);
  const [fathers, setFathers] = useState([]);
  const [sposes, setSposes] = useState([]);

  useEffect(() => {
    const mother = family.filter((item) => item.gender === "F");
    const father = family.filter((item) => item.gender === "M");
    const sposes = family.filter(
      (item) => item.gender === "M" || item.gender === "F"
    );
    setMothers(mother);
    setFathers(father);
    setSposes(sposes);
  }, [family]);

  const addFamily = () => {
    setFamily([
      ...family,
      {
        familyGeneration: "",
        father: "",
        gender: "",
        health: "",
        key: family.length,
        lastName1: "",
        lastName2: "",
        mother: "",
        name: "",
        relationship: "",
        TypeOfStudy: "",
        job: "",
        lifeStatus: "",
      },
    ]);
  };

  const removeFamily = (index) => {
    const list = [...family];
    list.splice(index, 1);
    setFamily(list);
  };

  return (
    <div className="w-full pb-4 text-black text-justify">
      <span className="font-medium text-base lg:text-xl">
        {order}) {""} {title}
      </span>
      <h1>Por favor inicie con el familiar de mayor descendencia</h1>
      <div className={`flex flex-col my-2`}>
        {family.map((item, index) => (
          <div key={index} className="text-black flex flex-col w-full ">
            <div className="">
              <input
                type="text"
                id={`name${index}`}
                value={item.name}
                className="w-full border rounded-md p-2"
                {...register(`name${index}`)}
                placeholder="Nombre"
                onChange={(e) => {
                  const { value } = e.target;
                  const list = [...family];
                  list[index].name = value;
                  setFamily(list);
                }}
              />
            </div>
            <div className="mt-2">
              <input
                type="text"
                id={`lastName1${index}`}
                value={item.lastName1}
                className="w-full border rounded-md p-2"
                {...register(`lastName1${index}`)}
                placeholder="Apellido 1"
                onChange={(e) => {
                  const { value } = e.target;
                  const list = [...family];
                  list[index].lastName1 = value;
                  setFamily(list);
                }}
              />
            </div>
            <div className="mt-2">
              <input
                type="text"
                id={`lastName2${index}`}
                value={item.lastName2}
                className="w-full border rounded-md p-2"
                {...register(`lastName2${index}`)}
                placeholder="Apellido 2"
                onChange={(e) => {
                  const { value } = e.target;
                  const list = [...family];
                  list[index].lastName2 = value;
                  setFamily(list);
                }}
              />
            </div>
            <div className="mt-2">
              <select
                id={`gender${index}`}
                value={item.gender}
                className="w-full border rounded-md p-2"
                {...register(`gender${index}`)}
                onChange={(e) => {
                  const { value } = e.target;
                  const list = [...family];
                  list[index].gender = value;
                  setFamily(list);
                }}
              >
                <option value="0">Género</option>
                <option value="F">Femenino</option>
                <option value="M">Masculino</option>
              </select>
            </div>
            <div className="mt-2">
              <select
                id={`mother${index}`}
                value={item.mother}
                className="w-full border rounded-md p-2"
                {...register(`mother${index}`)}
                onChange={(e) => {
                  const { value } = e.target;
                  const list = [...family];
                  list[index].mother = value;
                  setFamily(list);
                }}
              >
                <option value="">Madre</option>
                {mothers.map((item) => (
                  <option key={item.key} value={item.key}>
                    {item.name + " " + item.lastName1 + " " + item.lastName2}
                  </option>
                ))}
              </select>
            </div>
            <div className="mt-2">
              <select
                id={`father${index}`}
                value={item.father}
                className="w-full border rounded-md p-2"
                {...register(`father${index}`)}
                onChange={(e) => {
                  const { value } = e.target;
                  const list = [...family];
                  list[index].father = value;
                  setFamily(list);
                }}
              >
                <option value="">Padre</option>
                {fathers.map((item) => (
                  <option key={item.key} value={item.key}>
                    {item.name + " " + item.lastName1 + " " + item.lastName2}
                  </option>
                ))}
              </select>
            </div>
            <div className="mt-2">
              <select
                id={`relationship${index}`}
                value={item.relationship}
                className="w-full border rounded-md p-2"
                {...register(`relationship${index}`)}
                onChange={(e) => {
                  const { value } = e.target;
                  const list = [...family];
                  list[index].relationship = value;
                  setFamily(list);
                }}
              >
                <option value="">Conyugue</option>
                {sposes.map((item) => (
                  <option key={item.key} value={item.key}>
                    {item.name + " " + item.lastName1 + " " + item.lastName2}
                  </option>
                ))}
              </select>
            </div>
            <div className="mt-2">
              <label>Generación Familiar</label>
              <div className="flex gap-5">
                <div className="flex gap-2 items-center">
                  <input
                    type="radio"
                    name="familyGeneration"
                    value="A"
                    {...register(`familyGeneration${index}`, {
                      onChange: (e) => {
                        const { value } = e.target;
                        const list = [...family];
                        list[index].familyGeneration = value;
                        setFamily(list);
                      },
                    })}
                  />
                  <label htmlFor="firstGene" className="mb-0">
                    Primera Generación
                  </label>
                </div>
                <div className="flex gap-2 items-center">
                  <input
                    type="radio"
                    name="familyGeneration"
                    value="B"
                    {...register(`familyGeneration${index}`, {
                      onChange: (e) => {
                        const { value } = e.target;
                        const list = [...family];
                        list[index].familyGeneration = value;
                        setFamily(list);
                      },
                    })}
                  />
                  <label htmlFor="secondGene" className="mb-0">
                    Segunda Generación
                  </label>
                </div>
                <div className="flex gap-2 items-center">
                  <input
                    type="radio"
                    name="familyGeneration"
                    value="C"
                    {...register(`familyGeneration${index}`, {
                      onChange: (e) => {
                        const { value } = e.target;
                        const list = [...family];
                        list[index].familyGeneration = value;
                        setFamily(list);
                      },
                    })}
                  />
                  <label htmlFor="thirdGene" className="mb-0">
                    Tercera Generación
                  </label>
                </div>
              </div>
            </div>
            <div className="mt-2">
              <label>Tipo de estudio</label>
              <div className="flex gap-5">
                <div className="flex gap-2 items-center">
                  <input
                    type="radio"
                    name="TypeOfStudy"
                    value="D"
                    {...register(`TypeOfStudy${index}`, {
                      onChange: (e) => {
                        const { value } = e.target;
                        const list = [...family];
                        list[index].TypeOfStudy = value;
                        setFamily(list);
                      },
                    })}
                  />
                  <label htmlFor="firstGene" className="mb-0">
                    Estudios Básicos
                  </label>
                </div>
                <div className="flex gap-2 items-center">
                  <input
                    type="radio"
                    name="TypeOfStudy"
                    value="E"
                    {...register(`TypeOfStudy${index}`, {
                      onChange: (e) => {
                        const { value } = e.target;
                        const list = [...family];
                        list[index].TypeOfStudy = value;
                        setFamily(list);
                      },
                    })}
                  />
                  <label htmlFor="secondGene" className="mb-0">
                    Estudios Medios
                  </label>
                </div>
                <div className="flex gap-2 items-center">
                  <input
                    type="radio"
                    name="TypeOfStudy"
                    value="F"
                    {...register(`TypeOfStudy${index}`, {
                      onChange: (e) => {
                        const { value } = e.target;
                        const list = [...family];
                        list[index].TypeOfStudy = value;
                        setFamily(list);
                      },
                    })}
                  />
                  <label htmlFor="thirdGene" className="mb-0">
                    Estudios Superiores
                  </label>
                </div>
              </div>
            </div>
            <div className="mt-2">
              <label>Opciones Laborales en la Empresa Familiar</label>
              <div className="flex gap-5">
                <div className="flex gap-2 items-center">
                  <input
                    type="radio"
                    name="job"
                    value="G"
                    {...register(`job${index}`, {
                      onChange: (e) => {
                        const { value } = e.target;
                        const list = [...family];
                        list[index].job = value;
                        setFamily(list);
                      },
                    })}
                  />
                  <label htmlFor="firstGene" className="mb-0">
                    Trabajan en la empresa familiar
                  </label>
                </div>
                <div className="flex gap-2 items-center">
                  <input
                    type="radio"
                    name="job"
                    value="H"
                    {...register(`job${index}`, {
                      onChange: (e) => {
                        const { value } = e.target;
                        const list = [...family];
                        list[index].job = value;
                        setFamily(list);
                      },
                    })}
                  />
                  <label htmlFor="secondGene" className="mb-0">
                    No trabaja en la empresa
                  </label>
                </div>
                <div className="flex gap-2 items-center">
                  <input
                    type="radio"
                    name="job"
                    value="I"
                    {...register(`job${index}`, {
                      onChange: (e) => {
                        const { value } = e.target;
                        const list = [...family];
                        list[index].job = value;
                        setFamily(list);
                      },
                    })}
                  />
                  <label htmlFor="thirdGene" className="mb-0">
                    Puesto de generencia en la empresa
                  </label>
                </div>
              </div>
            </div>
            <div className="mt-2">
              <label>Problemas de Salud y Bienestar (opcional)</label>
              <div className="flex gap-5">
                <div className="flex gap-2 items-center">
                  <input
                    type="radio"
                    name="health"
                    value="J"
                    {...register(`health${index}`, {
                      onChange: (e) => {
                        const { value } = e.target;
                        const list = [...family];
                        list[index].health = value;
                        setFamily(list);
                      },
                    })}
                  />
                  <label htmlFor="firstGene" className="mb-0">
                    Abuso de alcohol y drogas
                  </label>
                </div>
                <div className="flex gap-2 items-center">
                  <input
                    type="radio"
                    name="health"
                    value="K"
                    {...register(`health${index}`, {
                      onChange: (e) => {
                        const { value } = e.target;
                        const list = [...family];
                        list[index].health = value;
                        setFamily(list);
                      },
                    })}
                  />
                  <label htmlFor="secondGene" className="mb-0">
                    Problemas mentales/fisicos
                  </label>
                </div>
                <div className="flex gap-2 items-center">
                  <input
                    type="radio"
                    name="health"
                    value="L"
                    {...register(`health${index}`, {
                      onChange: (e) => {
                        const { value } = e.target;
                        const list = [...family];
                        list[index].health = value;
                        setFamily(list);
                      },
                    })}
                  />
                  <label htmlFor="thirdGene" className="mb-0">
                    Abuso de drogas + Problemas mentales/fisicos
                  </label>
                </div>
              </div>
            </div>
            <div className="mt-2">
              <label>Confirmación de Estado de Vida(opcional)</label>
              <div className="flex gap-2 items-center">
                <input
                  type="checkbox"
                  name="lifeStatus"
                  value="S"
                  {...register(`lifeStatus${index}`, {
                    onChange: (e) => {
                      const { value } = e.target;
                      const list = [...family];
                      list[index].lifeStatus = value;
                      setFamily(list);
                    },
                  })}
                />
                <label htmlFor="firstGene" className="mb-0">
                  Muerto
                </label>
              </div>
            </div>
            <button
              className="bg-red-600 px-4 py-1.5 rounded-lg text-white font-semibold my-4"
              onClick={() => removeFamily(index)}
              type="button"
            >
              Eliminar
            </button>
          </div>
        ))}
      </div>
      <button
        onClick={addFamily}
        type="button"
        className="bg-blue-600 px-4 py-1.5 rounded-lg text-white "
      >
        Agregar miembro
      </button>
    </div>
  );
};
