//Libs
import React from "react";
import { Redirect } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { selectCurrentUser } from "Redux/User/user-selectors";
import { setCurrentUser } from "Redux/User/user-actions";
//Actions
import { setCompanyData } from "Redux/DynamicConfigCompany/dcc-action";
import { setModuleOrganization } from "Redux/DynamicConfigCompany/dcc-action";
import { setOrganizationSeleted } from "Redux/Organization/organizarion-action";
//Services
import { signUp } from "Services/UserService";
import MixPanel from "Services/MixPanelService";
import { getInterests, getProfessions } from "Services/CompanyService";
//Config
import { ROUTES } from "Config/config";
//Selectors
import {
  selectCompany,
  selectOrganizacion,
} from "Redux/DynamicConfigCompany/dcc-select";
//Components
import FormInput from "Components/FormInput/FormInput";
import {
  Wrapper,
  Title,
  CustomButton,
  GoToSignin,
  ErrorLabel,
  Spinner,
} from "Components";
class SignUp extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      failMessage: true,
      professions: [],
      interests: [],
      newUser: {
        name: "",
        firstLastName: "",
        secondLastName: "",
        dni: "",
        phone: "",
        email: "",
        professionalCode: "",
        professionId: [],
        organizationId: [],
        password: "",
        confirmPassword: "",
        myInterests: [],
      },
      modalState: {
        setModalCopyCRVisible: false,
        setModalPolicyCRVisible: false,
      },
    };
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const {
      name,
      firstLastName,
      phone,
      email,
      password,
      confirmPassword,
      dni,
    } = this.state.newUser;

    // VALIDATORS
    if (!name || !firstLastName || !phone || !email || !dni) {
      //MixPanel unsuccessful signup
      MixPanel.track(MixPanel.TYPES.UNSUCCESSFUL_SIGNUP);
      this.setState({
        failMessage: "Debe llenar todos los campos requeridos [*]",
      });
      setTimeout(() => {
        this.setState({
          failMessage: true,
        });
      }, 5000);
      return;
    }

    //Password
    if (password !== confirmPassword) {
      //MixPanel unsuccessful signup
      MixPanel.track(MixPanel.TYPES.UNSUCCESSFUL_SIGNUP);

      this.setState({ failMessage: "Las contraseñas no coinciden" });
      setTimeout(() => {
        this.setState({
          failMessage: true,
        });
      }, 5000);
      return;
    }

    try {
      this.setState({
        loading: true,
      });
      const signupResult = await signUp({
        ...this.state.newUser,
        companyId: this.props.company.id,
        countryId: this.props.company.countryId,
      });
      //MixPanel successFul signup
      MixPanel.track(MixPanel.TYPES.SUCCESSFUL_SIGNUP);
      this.setState({
        loading: false,
      });
      if (signupResult?.status === 409) {
        this.setState({
          failMessage: signupResult.response,
        });

        setTimeout(() => {
          this.setState({
            failMessage: true,
          });
        }, 5000);
        return;
      }
      if (!signupResult) {
        this.setState({
          failMessage: signupResult.response,
        });
        setTimeout(() => {
          this.setState({
            failMessage: true,
          });
        }, 5000);
        return;
      }
      if (signupResult.organizations.length <= 1) {
        this.props.setOrganizationModule(signupResult.organizations[0]);
        await this.props.setOrganizationSeleted(signupResult.organizations[0]);
      }
      this.props.setCurrentUser(signupResult);
    } catch (error) {
      this.setState({
        loading: false,
        failMessage:
          "Hubo un problema al crear la cuenta. Por favor vuelva a intentarlo",
      });
      setTimeout(() => {
        this.setState({
          failMessage: true,
        });
      }, 5000);
    }
  };

  handleChange = (event) => {
    let { name, value } = event.target;

    if (name === "email") {
      value = value.toLowerCase();
    }

    this.setState({
      newUser: {
        ...this.state.newUser,
        [name]: value,
      },
    });
  };

  handleChangeSelect = (name, value) => {
    if (name !== "interestId") {
      if (name === "organizationId") {
        this.setState({ professions: [] });
        this.setState({ interests: [] });
        getProfessions(window.location.host).then((professions = []) =>
          this.setState({ professions })
        );
        getInterests(window.location.host).then((interests = []) =>
          this.setState({ interests })
        );
      }
      this.setState({
        newUser: {
          ...this.state.newUser,
          [name]: value,
        },
      });
    } else {
      this.setState({
        newUser: {
          ...this.state.newUser,
          myInterests: value,
        },
      });
    }
  };

  handleHideModal = () => {
    this.setState({
      modalState: {
        setModalCopyCRVisible: false,
        setModalPolicyCRVisible: false,
      },
    });
  };

  render() {
    const { loading, failMessage } = this.state;
    const {
      name,
      firstLastName,
      secondLastName,
      phone,
      email,
      password,
      confirmPassword,
      dni,
    } = this.state.newUser;
    const { currentUser } = this.props;
    //all organizacion company
    return currentUser ? (
      <Redirect to={ROUTES.HOME} />
    ) : (
      <Wrapper width="100vw">
        {loading && <Spinner />}
        <div
          className="bg-cover bg-center bg-sign-up-background min-h-full min-w-full 
         filter brightness-background w-full h-auto fixed top-0 left-0"
        />
        <form
          className="w-4/5 my-8 mx-auto pt-5 pl-0 pb-8 pr-0 
          flex flex-col shadow-none bg-primary-signup-box bg-opacity-10 relative h-auto items-center rounded-3xl"
          action="POST"
          onSubmit={this.handleSubmit}
        >
          <Title $loginTittle>Regístrate</Title>

          <FormInput
            name="name"
            type="name"
            maxLength={100}
            value={name}
            onChange={this.handleChange}
            label="Nombre*"
            required
          />

          <FormInput
            name="firstLastName"
            type="firstLastName"
            maxLength={100}
            value={firstLastName}
            onChange={this.handleChange}
            label="Primer apellido*"
            required
          />

          <FormInput
            name="secondLastName"
            type="secondLastName"
            maxLength={100}
            value={secondLastName}
            onChange={this.handleChange}
            label="Segundo apellido"
          />

          <FormInput
            name="phone"
            type="phone"
            maxLength={20}
            value={phone}
            onChange={this.handleChange}
            label="Teléfono*"
            required
          />

          <FormInput
            name="email"
            type="email"
            maxLength={150}
            value={email}
            onChange={this.handleChange}
            label="Correo electrónico*"
            required
          />

          <FormInput
            name="dni"
            type="dni"
            maxLength={100}
            value={dni}
            onChange={this.handleChange}
            label="Cedula de identidad"
          />

          <FormInput
            name="password"
            type="password"
            maxLength={50}
            minLength={8}
            value={password}
            onChange={this.handleChange}
            label="Contraseña*"
            required
          />

          <FormInput
            name="confirmPassword"
            type="password"
            maxLength={50}
            minLength={8}
            value={confirmPassword}
            onChange={this.handleChange}
            label="Confirmar Contraseña*"
            required
          />
          <CustomButton $singupbutton>Registrarme</CustomButton>
          <ErrorLabel value={failMessage} />
          <GoToSignin />
        </form>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: selectCurrentUser(state),
  company: selectCompany(state),
  organizations: selectOrganizacion(state),
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  setCompanyData: (data) => dispatch(setCompanyData(data)),
  setOrganizationModule: (organizationSelect) =>
    dispatch(setModuleOrganization(organizationSelect)),
  setOrganizationSeleted: (organization) =>
    dispatch(setOrganizationSeleted(organization)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  React.memo(SignUp)
);
